blockquote {
  margin: 0 0 1em;
  padding: 0.1em 1em;
  border-left: 4px solid #ccc;
  color: #555;
  background: rgba(0, 0, 0, 0.05);
  font-size: 0.95em;
}
li code {
  color: coral;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
  
  th, td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: left;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
}

@primary-color: #1DA57A;