.home_container2 {
  height: 100%;
  text-align: center;
  .bg {
    display: flex;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: url("./images/bg-1.jpg") center center / cover no-repeat
      rgb(102, 102, 102);
    //   backgroundPosition: 'center center',
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat',
    //   backgroundColor: 'rgb(102, 102, 102)'
    .overlay {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      -moz-opacity: 0.55;
      opacity: 0.55;
    }
  }
  .outBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    cursor: pointer;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      // .headBox {
      //   width: 100%;
      //   display: flex;
      //   justify-content: center;
      //   border: 1px solid #fff;
      // }
      .headerBox {
        width: 16rem;
        height: 8rem;
        display: flex;
        justify-content: center;
        padding: 0;
        cursor: pointer;
        position: relative;
        &:hover {
          img {
            transform: rotateY(150deg);
          }
        }
        img {
          user-select: none;
          width: 8rem;
          height: 8rem;
          border: 1px solid #9f9f9f;

          border-radius: 50%;
          transition: all 1s;
          transform-origin: 0 50%;
        }
        .headBottom {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: -1;
          .headTxt {
            background-color: rgb(51, 51, 51);
            user-select: none;
            font-size: 1.3rem;
            color: #fff;
            width: 8rem;
            height: 8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
        }
      }
      .title {
        font-size: 1.5rem;
        padding: 1rem 2rem;
        border-bottom: 1px solid #9e9c9c;
      }
      .description {
        font-size: 1rem;
        padding-top: 1rem;
        color: #efefef;
        cursor: pointer;
        .name {
          color: #fff;

          font-weight: 700;
        }
      }
      .btnBox {
        display: flex;
        padding-top: 2rem;
        justify-content: space-around;
        color: #fff;
        .btn {
          cursor: pointer;
          padding: 0.5rem 1rem;
          border: 1px solid #ccc;
          border-radius: 1.5rem;
          color: #fff;
          &:nth-child(2) {
            margin: 0 1rem;
          }
          &:hover {
            border-color: rgb(255, 255, 255);
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;