.blogContainer {
  //   background-color: #787878;
  padding-bottom: 1rem;
  img {
    display: block;
  }
  .blogTitle {
    margin: 0 15rem;
    font-size: 2rem;
    font-weight: bold;
  }
  .ctxContainer {
    margin: 2rem 15rem;
    border: 1px solid #9c9999;
    padding: 1rem;
    background-color: #fff;
  }
}
.mobileBlogContainer {
  padding-bottom: 1rem;
  img {
    display: block;
  }
  .blogTitle {
    margin: 0 0.5rem;
    font-size: 2rem;
    font-weight: bold;
  }
  .ctxContainer {
    overflow-x: hidden;
    padding: 0 0.5rem;
    // border: 1px solid #9c9999;
    border-top: 1px dashed #9c9999;
    // padding: 1rem;
    background-color: #fff;
  }
}

@primary-color: #1DA57A;