.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  color: #fff;
  height: 60px;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 0 1rem;
    height: 50px;
    background: linear-gradient(90deg, rgba(0, 150, 136, 1) 0%, rgba(0, 188, 212, 1) 100%);
  }

  .title {
    padding-top: 0.5rem;
  }
  .headerNav {
    display: flex;
    p {
      font-weight: 600;
      margin: 0 15px;
      position: relative;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      padding: 0.5rem;
    }
    p::after {
      content: "";
      position: absolute;
      left: 50%;
      background: #01d5ff;
      bottom: -3px;
      width: 0%;
      height: 1px;
    }
    p:hover::after {
      width: 100%;
      left: 0;
      transition: all 0.5s ease 0s;
    }
    p:hover {
      color: #885555;
      font-weight: bold;
      background-color: rgba(255, 255, 255, 1);
    }

    p:active {
      border: none;
      opacity: 0.5;
    }
  }
}

.isSticky {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
}

.mobileHeader {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    padding: 10px;
    z-index: 1001;
    
    .hamburger {
      width: 25px;
      height: 2px;
      background: #fff;
      position: relative;
      transition: all 0.3s ease;
      
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 25px;
        height: 2px;
        background: #fff;
        transition: all 0.3s ease;
      }
      
      &::before {
        top: -8px;
        transform: rotate(0deg);
      }
      
      &::after {
        top: 8px;
        transform: rotate(0deg);
      }

      &.open {
        background: transparent;
        
        &::before {
          top: 0;
          transform: rotate(45deg);
        }
        
        &::after {
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.headerNav {
  @media (max-width: 768px) {
    display: none;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 150, 136, 0.98) 0%, rgba(0, 188, 212, 0.98) 100%);
    width: 100%;
    height: calc(100vh - 50px);
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1000;
    overflow-y: auto;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
    pointer-events: none;

    &.mobileOpen {
      display: flex;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      opacity: 1;
      pointer-events: auto;
    }

    p {
      margin: 10px 0;
      font-size: 16px;
      padding: 12px 16px;
      border-radius: 8px;
      width: 100%;
      color: #ffffff;
      transition: all 0.3s ease;
      background: transparent;
      text-align: left;

      &:hover {
        background: rgba(255, 255, 255, 0.15);
        color: #fff;
        transform: translateY(-2px);
      }

      &::after {
        display: none;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@primary-color: #1DA57A;