@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@primary-color: #6a11cb;
@secondary-color: #2575fc;
@text-color: #2c3e50;
@background-color: #f8f9fa;

.resume-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 80px;  // 增加顶部间距

  @media (max-width: 768px) {
    padding: 20px;
    margin-top: 60px;
    border-radius: 0;
    box-shadow: none;
  }
}

.resume-header {
  text-align: center;
  margin-bottom: 60px;
  padding: 40px;
  background: linear-gradient(135deg, @primary-color, @secondary-color);
  border-radius: 12px;
  color: white;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 0;

    &::before,
    &::after {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -80px;
    right: -80px;
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
  }

  h1 {
    font-size: 42px;
    margin-bottom: 15px;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }

  .resume-info {
    p {
      margin: 8px 0;
      font-size: 16px;
      opacity: 0.9;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    a {
      color: white;
      text-decoration: none;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.8;
        border-bottom-color: white;
      }
    }
  }
}

.resume-section {
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(@primary-color, 0.1);
    color: @text-color;
    position: relative;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 60px;
      height: 2px;
      background: linear-gradient(90deg, @primary-color, @secondary-color);
    }
  }

  .experience-item {
    margin-bottom: 40px;
    padding: 25px;
    background: @background-color;
    border-radius: 8px;
    transition: all 0.3s ease;
    border-left: 4px solid transparent;

    @media (max-width: 768px) {
      margin-bottom: 20px;
      padding: 15px;
      border-radius: 4px;
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
      border-left-color: @primary-color;
    }

    h3 {
      font-size: 22px;
      margin-bottom: 12px;
      color: @text-color;
    }

    .duration {
      color: lighten(@text-color, 20%);
      margin-bottom: 15px;
      font-size: 14px;
    }

    ul {
      padding-left: 20px;

      li {
        margin-bottom: 10px;
        line-height: 1.7;
        color: lighten(@text-color, 10%);
        position: relative;

        &::before {
          content: '•';
          color: @primary-color;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
}

@primary-color: #1DA57A;